import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/Link';
import Arrow from './Arrow';

const CallToAction = ({
  linkText,
  title,
  url,
}) => (
  <div className="cta--content container--narrow">
    <h2>{title}</h2>

    <Link to={url}>
      {linkText}
      <Arrow className="pl2" />
    </Link>
  </div>
);

CallToAction.propTypes = {
  linkText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default CallToAction;
