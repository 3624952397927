import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

const iconColor = '#b4b4b4';
const iconSize = 43;

const SocialMedia = ({
  linkedin,
  facebook,
  twitter,
}) => {
  if (!linkedin && !facebook && !twitter) {
    return null;
  }

  const linkedinButton = () => {
    if (!linkedin) {
      return null;
    }

    return (
      <OutboundLink href={linkedin}>
        <LinkedinIcon
          iconBgStyle={{ fill: 'transparent' }}
          logoFillColor={iconColor}
          size={iconSize}
        />
      </OutboundLink>
    );
  };

  const facebookButton = () => {
    if (!facebook) {
      return null;
    }

    return (
      <OutboundLink href={facebook}>
        <FacebookIcon
          iconBgStyle={{ fill: 'transparent' }}
          logoFillColor={iconColor}
          size={iconSize}
        />
      </OutboundLink>
    );
  };

  const twitterButton = () => {
    if (!twitter) {
      return null;
    }

    return (
      <OutboundLink href={twitter}>
        <TwitterIcon
          iconBgStyle={{ fill: 'transparent' }}
          logoFillColor={iconColor}
          size={iconSize}
        />
      </OutboundLink>
    );
  };

  return (
    <div className="social-share">
      <div className="social-share--container">
        <div className="social-share--title">
          Social Media
        </div>

        <div className="social-share--links">
          {linkedinButton()}

          {twitterButton()}

          {facebookButton()}
        </div>
      </div>
    </div>
  );
};

SocialMedia.propTypes = {
  linkedin: PropTypes.string,
  facebook: PropTypes.string,
  twitter: PropTypes.string,
};

SocialMedia.defaultProps = {
  linkedin: null,
  facebook: null,
  twitter: null,
};

export default SocialMedia;
