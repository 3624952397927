import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import PageTemplate from 'templates/PageTemplate';
import Link from 'components/Link';
import SocialMedia from 'components/SocialMedia';
import CallToAction from 'components/CallToAction';
import CircleArrow from 'components/CircleArrow';

const TeamMemberTemplate = ({ data, pathContext }) => {
  const { html } = data.record;
  const {
    ctatitle,
    ctalink,
    ctaurl,
    metadescription,
    position,
    socialmedia,
    title,
  } = data.record.frontmatter;
  const { previous, next } = pathContext;
  let linkedin;
  let facebook;
  let twitter;

  const getCta = () => {
    if (ctalink && ctatitle && ctaurl) {
      return (
        <aside className="callToAction">
          <CallToAction
            linkText={ctalink}
            title={ctatitle}
            url={ctaurl}
          />
        </aside>
      );
    }

    return null;
  };

  if (socialmedia) {
    ({ linkedin, facebook, twitter } = socialmedia);
  }

  return (
    <PageTemplate className="team" metaDescription={metadescription}>
      <Helmet title={title} />

      <div className="container">
        <header>

          <div className="profile">
            <h1>
              {title}
            </h1>

            <h2>
              {position}
            </h2>

            <SocialMedia
              linkedin={linkedin}
              facebook={facebook}
              twitter={twitter}
            />
          </div>

        </header>

        <div className="team--content">
          <nav className="prev">
            <Link to={`/team${previous.fields.slug}`}>
              <h3>
                {previous.frontmatter.title}
              </h3>

              <CircleArrow left />
            </Link>
          </nav>

          <div className="team--body">
            <section
              className="content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: html }}
            />

            <div className="tc mt5">
              <SocialMedia
                linkedin={linkedin}
                facebook={facebook}
                twitter={twitter}
              />
            </div>
          </div>

          <nav className="next">
            <Link to={`/team${next.fields.slug}`}>
              <h3>
                {next.frontmatter.title}
              </h3>

              <CircleArrow />
            </Link>
          </nav>
        </div>
      </div>

      {getCta()}
    </PageTemplate>
  );
};

export const pageQuery = graphql`
  query TeamMemberBySlug($slug: String!) {
    record: markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: {regex: "/(../team)/.*.md$/"}
      frontmatter: { example: { ne: true } }
    ) {
      html
      fields {
        slug
      }
      frontmatter {
        position
        title
        ctatitle
        ctalink
        ctaurl
        metadescription
        socialmedia {
          linkedin
          twitter
          facebook
        }
      }
    }
  }
`;

TeamMemberTemplate.propTypes = {
  data: PropTypes.shape({
    record: PropTypes.object.isRequired,
  }).isRequired,
  pathContext: PropTypes.shape({
    previous: PropTypes.object.isRequired,
    next: PropTypes.object.isRequired,
  }).isRequired,
};

export default TeamMemberTemplate;
